@charset "UTF-8";
/*
Theme Name: Montero | One Page Parallax HTML5 Template
Author: Markhor Themes
Author URI: https://themeforest.net/user/htmlbeans
Version: 1
Description: Base theme for HTML5
Text Domain: base
Tags: one-column, two-columns, three-columns
Theme URI: http://www.htmlbeans.com/html/Montero/
*/
/* ---------------------------------------------------------*/
/*------------------------------------------------------------------

1. Logo / .logo
2. PageHeader / .pageHeader
3. HeaderFixed / .headerFixed
4. MainNavHolder / .mainNavHolder
5. NavToggler / .NavToggler
6. body / body
7. pageWrapper / .pageWrapper
8. h1 , .h1 / h1 , .h1
9. h2 , .h2 / h2 , .h2
10. h3 , .h3 / h3 , .h3
11. h4 , .h4 / h2 , .h4
12. introBlock / .introBlock
13. iconGroupImg / .iconGroupImg
14. caption / .caption
15. playBtn / .playBtn
16. videoWatchHolder / .videoWatchHolder
17. btn-outline-primary / .btn-outline-primary
18. btn / .btn
19. abtBlock / .abtBlock
20. header / .header
21. header / .header
22. serviceBlock / .serviceBlock
23. serviceDesList / .serviceDesList
24. serviceDesList / .serviceDesList
25. videoHolder / .videoHolder
26. playBtnWrap / .playBtnWrap
27. playBtnHolder / .playBtnHolder
28. click / .click
29. svgBg / .svgBg
30. priceColumeBLock / .priceColumeBLock
31. priceVocherWrap / .priceVocherWrap
32. currency / .currency
33. pricePrsont / .pricePrsont
34. priceDesList / .priceDesList
35. testimonialBlock / .testimonialBlock
36. testimonial / .testimonial
37. avatarGroup / .avatarGroup
38. contactBlock / .contactBlock
39. srvcePromeBlock / .srvcePromeBlock
40. contactPopup / .contactPopup
41. contactForm / .contactForm
42. thankyouBlock / .thankyouBlock
43. iconGroupImgII / .iconGroupImgII
44. ovelGradientII / .ovelGradientII
45. thankyouBlock / .thankyouBlock
46. back-top / #back-top
47. loader-holder / .loader-holder
48. pageFooter / .pageFooter
49. circleGradient / .circleGradient
50. ovelGradient / .ovelGradient
51. fLogo / .fLogo
52. fLink / .fLink
53. socialNetworks / .socialNetworks
54. footerArea / .footerArea

-------------------------------------------------------------------*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*------------------------------------------------------------------
    6. body / body
    -------------------------------------------------------------------*/
body {
  -ms-overflow-style: scrollbar;
  min-width: 320px;
}

@media (min-width: 576px) {
  body {
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  body {
    line-height: 28px;
  }
}
/*------------------------------------------------------------------
    7. pageWrapper / .pageWrapper
    -------------------------------------------------------------------*/
.pageWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
}

/*------------------------------------------------------------------
    8. h1 , .h1 / h1 , .h1
    -------------------------------------------------------------------*/
@media (min-width: 576px) {
  h1,
.h1 {
    font-size: 38px;
  }
}
@media (min-width: 768px) {
  h1,
.h1 {
    font-size: 38px;
  }
}
@media (min-width: 992px) {
  h1,
.h1 {
    font-size: 45px;
    line-height: 52px;
  }
}
@media (min-width: 1230px) {
  h1,
.h1 {
    font-size: 55px;
    line-height: 72px;
  }
}
/*------------------------------------------------------------------
    9. h2 , .h2 / h2 , .h2
    -------------------------------------------------------------------*/
@media (min-width: 768px) {
  h2,
.h2 {
    font-size: 35px;
  }
}
@media (min-width: 992px) {
  h2,
.h2 {
    font-size: 40px;
  }
}
/*------------------------------------------------------------------
    10. h3 , .h3 / h3 , .h3
    -------------------------------------------------------------------*/
@media (min-width: 576px) {
  h3,
.h3 {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  h3,
.h3 {
    font-size: 35px;
  }
}
/*------------------------------------------------------------------
    11. h4 , .h4 / h2 , .h4
    -------------------------------------------------------------------*/
@media (min-width: 576px) {
  h4,
.h4 {
    font-size: 26px;
  }
}
@media (min-width: 768px) {
  h4,
.h4 {
    font-size: 30px;
  }
}
.fwSemibold {
  font-weight: 600;
}

/*------------------------------------------------------------------
    12. introBlock / .introBlock
    -------------------------------------------------------------------*/
.introBlock {
  z-index: 1;
  min-height: 100vh;
}

.introBlock .imgHolder {
  max-width: 440px;
}

@media (min-width: 768px) {
  .introBlock .imgHolder {
    max-width: 450px;
  }
}
@media (min-width: 992px) {
  .introBlock .imgHolder {
    max-width: 571px;
  }
}
/*------------------------------------------------------------------
    13. iconGroupImg / .iconGroupImg
    -------------------------------------------------------------------*/
.iconGroupImg .icnImg {
  z-index: -1;
}

.iconGroupImg .icnImg[data-icon=icon1] {
  top: 64px;
  left: 50%;
  margin-left: -700px;
  width: 102px;
  height: 122px;
  -webkit-transform: translateX(-50%) rotate(360deg);
  -ms-transform: translateX(-50%) rotate(360deg);
  transform: translateX(-50%) rotate(360deg);
}

.iconGroupImg .icnImg[data-icon=icon2] {
  top: 160px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-left: 30px;
  width: 20px;
  height: 20px;
}

@media (min-width: 992px) {
  .iconGroupImg .icnImg[data-icon=icon2] {
    width: 24px;
    height: 24px;
  }
}
.iconGroupImg .icnImg[data-icon=icon3] {
  top: 174px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-left: 573px;
  width: 78px;
  height: 79px;
}

.iconGroupImg .icnImg[data-icon=icon4] {
  top: 49.5%;
  left: 50%;
  -webkit-transform: translate(-50%, -50.5%);
  -ms-transform: translate(-50%, -50.5%);
  transform: translate(-50%, -50.5%);
  width: 62px;
  height: 61px;
  margin-left: -845px;
}

.iconGroupImg .icnImg[data-icon=icon5] {
  left: 50%;
  top: 47%;
  margin-left: 663px;
  width: 31px;
  height: 31px;
  -webkit-transform: translate(-50%, -53%);
  -ms-transform: translate(-50%, -53%);
  transform: translate(-50%, -53%);
}

.iconGroupImg .icnImg[data-icon=icon6] {
  left: 50%;
  bottom: 19.7%;
  margin-left: -50px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 69px;
  height: 95px;
}

@media (min-width: 992px) {
  .iconGroupImg .icnImg[data-icon=icon6] {
    margin-left: -120px;
  }
}
/*------------------------------------------------------------------
    14. caption / .caption
    -------------------------------------------------------------------*/
.caption p {
  margin-bottom: 26px;
}

.caption .btn-outline-primary {
  min-width: 160px;
  font-size: 12px;
}

/*------------------------------------------------------------------
    15. playBtn / .playBtn
    -------------------------------------------------------------------*/
.playBtn {
  width: 48px;
  height: 48px;
  background-color: #4a83e5;
  border: 1px solid #4177d4;
  -webkit-transition: background-color 0.35s ease, border 0.35s ease;
  -o-transition: background-color 0.35s ease, border 0.35s ease;
  transition: background-color 0.35s ease, border 0.35s ease;
}

@media (min-width: 576px) {
  .playBtn {
    width: 55px;
    height: 55px;
  }
}
.playBtn:before {
  animation-duration: 1s;
  border: 6px solid #4a83e5;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  border-radius: 50%;
  -webkit-animation-name: hvr-ripple-out;
  animation-name: hvr-ripple-out;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.playBtn .playIcn {
  width: 0;
  height: 0;
  margin-left: 3px;
  border-width: 6px 0px 6px 10px;
  border-style: solid;
  border-color: transparent transparent transparent #fff;
  -webkit-transition: border-color 0.35s ease;
  -o-transition: border-color 0.35s ease;
  transition: border-color 0.35s ease;
}

.playBtn:hover {
  background-color: #fff;
}

.playBtn:hover .playIcn {
  border-color: transparent transparent transparent #4a83e5;
}

@-webkit-keyframes hvr-ripple-out {
  100% {
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    opacity: 0;
  }
}
@keyframes hvr-ripple-out {
  100% {
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    opacity: 0;
  }
}
/*------------------------------------------------------------------
    16. videoWatchHolder / .videoWatchHolder
    -------------------------------------------------------------------*/
.videoWatchHolder {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
}

.videoWatchHolder p {
  margin-bottom: 0;
}

/*------------------------------------------------------------------
    17. btn-outline-primary / .btn-outline-primary
    -------------------------------------------------------------------*/
.btn-outline-primary {
  border-style: dashed;
  background-color: #fff;
  line-height: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
  min-width: 150px;
  font-size: 11px;
}

.btn-outline-primary:active,
.btn-outline-primary:focus {
  outline: none;
}

/*------------------------------------------------------------------
    18. btn / .btn
    -------------------------------------------------------------------*/
.btn:active,
.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*------------------------------------------------------------------
    19. abtBlock / .abtBlock
    -------------------------------------------------------------------*/
.abtBlock .imgHolder {
  max-width: 400px;
}

@media (min-width: 768px) {
  .abtBlock .imgHolder {
    max-width: 470px;
  }
}
/*------------------------------------------------------------------
    20. header / .header
    -------------------------------------------------------------------*/
.header .h3 {
  line-height: 35px;
}

@media (min-width: 576px) {
  .header .h3 {
    line-height: 38px;
  }
}
@media (min-width: 992px) {
  .header .h3 {
    line-height: 45px;
  }
}
@media (min-width: 1230px) {
  .header .h3 {
    line-height: 50px;
  }
}
/*------------------------------------------------------------------
    21. header / .header
    -------------------------------------------------------------------*/
.abtDesBlock {
  font-size: 14px;
  line-height: 22px;
}

@media (min-width: 992px) {
  .abtDesBlock {
    font-size: 15px;
    line-height: 24px;
  }
}
@media (min-width: 1230px) {
  .abtDesBlock {
    font-size: 16px;
    line-height: 28px;
  }
}
@media (min-width: 768px) and (max-width: 1229.98px) {
  .abtDesBlock .h5 {
    font-size: 18px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .abtDesBlock .h2 {
    font-size: 30px;
  }
}
/*------------------------------------------------------------------
    22. serviceBlock / .serviceBlock
    -------------------------------------------------------------------*/
.serviceBlock {
  z-index: 1;
}

/*------------------------------------------------------------------
    23. serviceDesList / .serviceDesList
    -------------------------------------------------------------------*/
@media (min-width: 768px) {
  .serviceDesList [class*=col]:nth-child(odd) {
    margin-top: 40px;
  }
}
/*------------------------------------------------------------------
    24. serviceDesList / .serviceDesList
    -------------------------------------------------------------------*/
.serviceDesBlock .icn {
  height: 80px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .serviceDesBlock .icn {
    height: 67px;
  }
}
.serviceDesBlock .icn:before {
  font-size: 70px;
  line-height: 72px;
  margin-left: 0;
}

@media (min-width: 768px) {
  .serviceDesBlock .icn:before {
    font-size: 62px;
    line-height: 65px;
  }
}
@media (min-width: 992px) {
  .serviceDesBlock .icn:before {
    font-size: 70px;
    line-height: 72px;
  }
}
@media (min-width: 1230px) {
  .serviceDesBlock .icn:before {
    font-size: 82px;
    line-height: 82px;
  }
}
.serviceDesBlock:hover .label {
  opacity: 1;
  visibility: visible;
}

.serviceDesBlock:hover:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .serviceDesBlock .h5 {
    font-size: 16px;
  }
}
.serviceDesBlock .h5 a {
  color: #222;
}

.serviceDesBlock.active .label {
  opacity: 1;
  visibility: visible;
}

.serviceDesBlock.active:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.serviceDesBlock:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.35s ease;
  transition: -webkit-transform 0.35s ease;
  -o-transition: transform 0.35s ease;
  transition: transform 0.35s ease;
  transition: transform 0.35s ease, -webkit-transform 0.35s ease;
}

.serviceDesBlock[data-icnClr=primary] .icn {
  color: #fc4d4a;
}

.serviceDesBlock[data-icnClr=primary]:after {
  background-color: #fc4d4a;
}

.serviceDesBlock[data-icnClr=info] .icn {
  color: #01ebac;
}

.serviceDesBlock[data-icnClr=info]:after {
  background-color: #01ebac;
}

.serviceDesBlock[data-icnClr=purple] .icn {
  color: #9756f8;
}

.serviceDesBlock[data-icnClr=purple]:after {
  background-color: #9756f8;
}

.serviceDesBlock[data-icnClr=orange] .icn {
  color: #ff930d;
}

.serviceDesBlock[data-icnClr=orange]:after {
  background-color: #ff930d;
}

.serviceDesBlock .label {
  top: 15px;
  right: 15px;
  font-size: 12px;
  line-height: 14px;
  padding: 3px 7px;
  opacity: 0;
  cursor: default;
  visibility: hidden;
  -webkit-transition: opacity 0.35s ease, visibility 0.35s ease;
  -o-transition: opacity 0.35s ease, visibility 0.35s ease;
  transition: opacity 0.35s ease, visibility 0.35s ease;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .serviceDesBlock .label {
    top: 12px;
    right: 12px;
    font-size: 10px;
    line-height: 12px;
  }
}
/*------------------------------------------------------------------
    25. videoHolder / .videoHolder
    -------------------------------------------------------------------*/
.videoHolder {
  max-width: 100%;
}

/*------------------------------------------------------------------
    26. playBtnWrap / .playBtnWrap
    -------------------------------------------------------------------*/
.playBtnWrap {
  width: 60px;
  height: 60px;
}

@media (min-width: 576px) {
  .playBtnWrap {
    width: 70px;
    height: 70px;
  }
}
@media (min-width: 768px) {
  .playBtnWrap {
    width: 80px;
    height: 80px;
  }
}
.playBtnWrap:before,
.playBtnWrap:after {
  position: absolute;
  content: "";
  border: 1px solid #fff;
  border-radius: 100%;
  -webkit-animation: myOrbit 4s infinite;
  animation: myOrbit 4s infinite;
}

.playBtnWrap:before {
  top: -20px;
  right: -20px;
  width: 100px;
  height: 100px;
}

@media (min-width: 576px) {
  .playBtnWrap:before {
    width: 110px;
    height: 110px;
  }
}
@media (min-width: 768px) {
  .playBtnWrap:before {
    width: 120px;
    height: 120px;
  }
}
.playBtnWrap:after {
  top: -10px;
  right: -10px;
  width: 80px;
  height: 80px;
}

@media (min-width: 576px) {
  .playBtnWrap:after {
    width: 90px;
    height: 90px;
  }
}
@media (min-width: 768px) {
  .playBtnWrap:after {
    width: 100px;
    height: 100px;
  }
}
@-webkit-keyframes myOrbit {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes myOrbit {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------------------------------------------------
    27. playBtnHolder / .playBtnHolder
    -------------------------------------------------------------------*/
.playBtnHolder {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.playBtnHolder .playIcn {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 15px;
  border-color: transparent transparent transparent #222;
}

.playBtnHolder .txt {
  font-size: 13px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .playBtnHolder .txt {
    font-size: 15px;
    line-height: 24px;
  }
}
@media (max-width: 575.98px) {
  .playBtnHolder .txt {
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  }
}
.playBtnHolder .arrowImg {
  max-width: 17px;
  right: -31px;
  bottom: 13px;
}

/*------------------------------------------------------------------
    28. click / .click
    -------------------------------------------------------------------*/
.click {
  color: #3062b8;
  text-decoration: underline;
  line-height: 18px;
}

/*------------------------------------------------------------------
    29. svgBg / .svgBg
    -------------------------------------------------------------------*/
.svgBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/*------------------------------------------------------------------
    30. priceColumeBLock / .priceColumeBLock
    -------------------------------------------------------------------*/
.priceColumeBLock {
  border: 1px dashed rgba(252, 77, 74, 0.3);
  background-color: rgba(252, 77, 74, 0.05);
}

.priceColumeBLock[data-colorBdr=info] {
  border-color: rgba(1, 235, 172, 0.3);
  background-color: rgba(1, 235, 172, 0.05);
}

.priceColumeBLock[data-colorBdr=info] .priceVocherWrap {
  color: #01ebac;
}

.priceColumeBLock[data-colorBdr=purple] {
  border-color: rgba(151, 86, 248, 0.3);
  background-color: rgba(151, 86, 248, 0.05);
}

.priceColumeBLock[data-colorBdr=purple] .priceVocherWrap {
  color: #9756f8;
}

.priceColumeBLock[data-colorBdr=purple] .btn {
  background-color: #9756f8;
  border-color: #9756f8;
}

.priceColumeBLock[data-colorBdr=purple] .btn:hover {
  background-color: #8c45f6;
  border-color: #8c45f6;
}

.priceColumeBLock .imgHolder {
  max-width: 212px;
  max-height: 216px;
}

@media (min-width: 576px) {
  .priceColumeBLock .imgHolder {
    max-width: 178px;
    max-height: 182px;
  }
}
@media (min-width: 992px) {
  .priceColumeBLock .imgHolder {
    max-width: 198px;
    max-height: 202px;
  }
}
@media (min-width: 1230px) {
  .priceColumeBLock .imgHolder {
    max-width: 264px;
    max-height: 270px;
  }
}
.priceColumeBLock .btn {
  min-width: 170px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 11px;
  color: #fff;
}

@media (min-width: 768px) {
  .priceColumeBLock .btn {
    min-width: 192px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .priceColumeBLock .h4 {
    font-size: 25px;
  }
}
@media (min-width: 992px) and (max-width: 1229.98px) {
  .priceColumeBLock .h4 {
    font-size: 26px;
  }
}
/*------------------------------------------------------------------
    31. priceVocherWrap / .priceVocherWrap
    -------------------------------------------------------------------*/
.priceVocherWrap {
  font-size: 60px;
  line-height: 62px;
  color: #fc4d4a;
}

/*------------------------------------------------------------------
    32. currency / .currency
    -------------------------------------------------------------------*/
.currency {
  bottom: -32px;
  line-height: 24px;
}

/*------------------------------------------------------------------
    33. pricePrsont / .pricePrsont
    -------------------------------------------------------------------*/
.pricePrsont,
.currency {
  font-size: 22px;
  line-height: 24px;
}

.pricePrsont {
  top: -7px;
}

/*------------------------------------------------------------------
    34. priceDesList / .priceDesList
    -------------------------------------------------------------------*/
.priceDesList li {
  margin-bottom: 15px;
}

@media (min-width: 1230px) {
  .priceDesList li {
    margin-bottom: 18px;
  }
}
/*------------------------------------------------------------------
    35. testimonialBlock / .testimonialBlock
    -------------------------------------------------------------------*/
.testimonialBlock {
  min-height: 420px;
}

@media (min-width: 576px) {
  .testimonialBlock {
    min-height: 480px;
  }
}
@media (min-width: 992px) {
  .testimonialBlock {
    min-height: 530px;
  }
}
@media (min-width: 992px) {
  .testimonialBlock {
    min-height: 600px;
  }
}
@media (min-width: 1230px) {
  .testimonialBlock {
    min-height: 713px;
  }
}
/*------------------------------------------------------------------
    36. testimonial / .testimonial
    -------------------------------------------------------------------*/
.testimonial {
  color: #222;
}

.testimonial .imgHolder {
  width: 100%;
  height: 100%;
}

.testimonial q {
  font-size: 21px;
  line-height: 32px;
  margin-bottom: 12px;
}

@media (min-width: 576px) {
  .testimonial q {
    font-size: 24px;
    line-height: 38px;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) {
  .testimonial q {
    font-size: 26px;
    line-height: 45px;
  }
}
@media (min-width: 1230px) {
  .testimonial q {
    font-size: 30px;
    line-height: 52px;
  }
}
.testimonial .author {
  font-style: normal;
  color: #999;
}

.testimonial .quoteImg {
  width: 32px;
  height: 32px;
  top: 0;
  right: 0;
  padding: 10px;
}

@media (min-width: 768px) {
  .testimonial .quoteImg {
    width: 40px;
    height: 40px;
    padding: 12px;
  }
}
.testimonial .avatar {
  width: 100px;
  height: 100px;
}

@media (min-width: 768px) {
  .testimonial .avatar {
    width: 110px;
    height: 110px;
  }
}
@media (min-width: 1230px) {
  .testimonial .avatar {
    width: 120px;
    height: 120px;
  }
}
/*------------------------------------------------------------------
    37. avatarGroup / .avatarGroup
    -------------------------------------------------------------------*/
.avatarGroup .imgWrap {
  width: 60px;
  height: 60px;
}

@media (min-width: 992px) {
  .avatarGroup .imgWrap {
    width: 65px;
    height: 65px;
  }
}
@media (min-width: 992px) {
  .avatarGroup .imgWrap {
    width: 71px;
    height: 71px;
  }
}
.avatarGroup .imgWrap[data-img=imgI] {
  top: 41.5%;
  left: 6.3%;
  width: 100px;
  height: 100px;
  -webkit-transform: translateY(-58.5%);
  -ms-transform: translateY(-58.5%);
  transform: translateY(-58.5%);
}

@media (min-width: 1230px) {
  .avatarGroup .imgWrap[data-img=imgI] {
    left: 9.3%;
  }
}
.avatarGroup .imgWrap[data-img=imgII] {
  top: 33%;
  right: 11%;
  -webkit-transform: translateY(-67%);
  -ms-transform: translateY(-67%);
  transform: translateY(-67%);
}

@media (min-width: 1230px) {
  .avatarGroup .imgWrap[data-img=imgII] {
    right: 13.7%;
  }
}
.avatarGroup .imgWrap[data-img=imgIV] {
  top: 56.7%;
  right: -39px;
  width: 70px;
  height: 70px;
  -webkit-transform: translateY(-44.3%);
  -ms-transform: translateY(-44.3%);
  transform: translateY(-44.3%);
}

@media (min-width: 768px) {
  .avatarGroup .imgWrap[data-img=imgIV] {
    right: 4.5%;
    width: 80px;
    height: 80px;
  }
}
@media (min-width: 992px) {
  .avatarGroup .imgWrap[data-img=imgIV] {
    width: 100px;
    height: 100px;
  }
}
@media (min-width: 1230px) {
  .avatarGroup .imgWrap[data-img=imgIV] {
    right: 6.3%;
  }
}
.avatarGroup .imgWrap[data-img=imgIII] {
  bottom: 19.6%;
  right: 12.6%;
  width: 100px;
  height: 100px;
  -webkit-transform: translateY(80.4%);
  -ms-transform: translateY(80.4%);
  transform: translateY(80.4%);
}

@media (min-width: 1230px) {
  .avatarGroup .imgWrap[data-img=imgIII] {
    right: 14.5%;
  }
}
.avatarGroup .imgWrap[data-img=imgV] {
  bottom: 30.5%;
  left: -22px;
  -webkit-transform: translateY(59.5%);
  -ms-transform: translateY(59.5%);
  transform: translateY(59.5%);
}

@media (min-width: 768px) {
  .avatarGroup .imgWrap[data-img=imgV] {
    left: 4.8%;
  }
}
@media (min-width: 1230px) {
  .avatarGroup .imgWrap[data-img=imgV] {
    left: 6.8%;
  }
}
.avatarGroup .imgWrap[data-img=imgVI] {
  bottom: 23%;
  left: 17%;
  -webkit-transform: translateY(77%);
  -ms-transform: translateY(77%);
  transform: translateY(77%);
}

@media (min-width: 1230px) {
  .avatarGroup .imgWrap[data-img=imgVI] {
    left: 20%;
  }
}
/*------------------------------------------------------------------
    38. contactBlock / .contactBlock
    -------------------------------------------------------------------*/
.contactBlock {
  min-height: 500px;
}

@media (min-width: 576px) {
  .contactBlock {
    min-height: 550px;
  }
}
@media (min-width: 992px) {
  .contactBlock {
    min-height: 660px;
  }
}
@media (min-width: 1230px) {
  .contactBlock {
    min-height: 753px;
  }
}
.contactBlock p {
  margin-bottom: 39px;
}

.contactBlock .btnHolder .btn {
  min-width: 170px;
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 12px;
}

@media (min-width: 576px) {
  .contactBlock .btnHolder .btn {
    min-width: 192px;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 14px;
  }
}
.contactBlock .bgImg {
  width: 800px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 0;
  z-index: 0;
}

@media (min-width: 992px) {
  .contactBlock .bgImg {
    width: 900px;
  }
}
@media (min-width: 1230px) {
  .contactBlock .bgImg {
    width: 1088px;
  }
}
.contactBlock .header {
  z-index: 1;
}

@media (max-width: 575.98px) {
  .contactBlock .h1 {
    font-size: 30px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .contactBlock .h1 {
    font-size: 36px;
  }
}
.error,
.success {
  margin-bottom: 20px;
  border-radius: 0.25rem;
  padding: 12px 25px;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}

#msgSubmit {
  display: none;
}

#msgSubmit.error {
  display: block;
}

#msgSubmit.success {
  display: block;
}

.success {
  background-color: #01ebac;
}

.error {
  background-color: #fc4d4a;
}

/*------------------------------------------------------------------
    39. srvcePromeBlock / .srvcePromeBlock
    -------------------------------------------------------------------*/
.srvcePromeBlock {
  z-index: 1;
}

/*------------------------------------------------------------------
    40. contactPopup / .contactPopup
    -------------------------------------------------------------------*/
.contactPopup {
  max-width: 460px;
}

.contactPopup .h6 {
  font-size: 24px;
  line-height: 28px;
}

.contactPopup .close {
  top: 0;
  right: 0;
  font-size: 22px;
  line-height: 22px;
  padding: 16px 18px;
  opacity: 1;
}

.contactPopup .close span {
  -webkit-transition: color 0.35s ease;
  -o-transition: color 0.35s ease;
  transition: color 0.35s ease;
}

/*------------------------------------------------------------------
    41. contactForm / .contactForm
    -------------------------------------------------------------------*/
.contactForm p {
  margin-bottom: 32px;
}

@media (min-width: 576px) {
  .contactForm p {
    margin-bottom: 36px;
  }
}
@media (min-width: 768px) {
  .contactForm p {
    margin-bottom: 54px;
  }
}
.contactForm .form-control {
  margin-bottom: 20px;
}

.contactForm textarea.form-control {
  height: 170px;
  resize: none;
}

/*------------------------------------------------------------------
    42. thankyouBlock / .thankyouBlock
    -------------------------------------------------------------------*/
.thankyouBlock {
  background-color: rgba(112, 143, 189, 0.05);
}

/*------------------------------------------------------------------
    43. iconGroupImgII / .iconGroupImgII
    -------------------------------------------------------------------*/
.iconGroupImgII .icnImg[data-icon=iconImgI] {
  top: -26px;
  left: 50%;
  margin-left: -652px;
  display: none;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 102px;
  height: 122px;
  -webkit-animation: move 4s infinite;
  animation: move 4s infinite;
}

.iconGroupImgII .icnImg[data-icon=iconImgII] {
  left: 50%;
  top: -65px;
  margin-left: -80px;
  width: 24px;
  height: 24px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.iconGroupImgII .icnImg[data-icon=iconImgIII] {
  left: 50%;
  top: -101px;
  margin-left: 750px;
  width: 78px;
  height: 79px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.iconGroupImgII .icnImg[data-icon=iconImgIV] {
  left: 50%;
  top: 38.3%;
  margin-left: -753px;
  width: 62px;
  height: 61px;
  -webkit-transform: translate(-50%, -63%);
  -ms-transform: translate(-50%, -63%);
  transform: translate(-50%, -63%);
}

.iconGroupImgII .icnImg[data-icon=iconImgV] {
  top: 28%;
  left: 50%;
  margin-left: 659px;
  width: 31px;
  height: 31px;
  -webkit-transform: translate(-50%, -72%);
  -ms-transform: translate(-50%, -72%);
  transform: translate(-50%, -72%);
}

.iconGroupImgII .icnImg[data-icon=iconImgVI] {
  left: 50%;
  bottom: 146px;
  margin-left: -585px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 79px;
  height: 95px;
}

@-webkit-keyframes move {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes move {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------------------------------------------------
    44. ovelGradientII / .ovelGradientII
    -------------------------------------------------------------------*/
.ovelGradientII {
  top: 100px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-left: -737px;
}

/*------------------------------------------------------------------
    45. thankyouBlock / .thankyouBlock
    -------------------------------------------------------------------*/
.thankyouBlock .circleGradient[data-circle=circleI] {
  top: 41.6%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-left: 840px;
}

.thankyouBlock .circleGradient[data-circle=circleII] {
  top: 410px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-left: 657px;
}

/*------------------------------------------------------------------
    46. back-top / #back-top
    -------------------------------------------------------------------*/
#back-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  cursor: pointer;
  float: right;
  width: 50px;
  height: 50px;
  padding: 14px 0;
  z-index: 9;
  opacity: 0;
  background: #fc4d4a;
  color: #fff;
  visibility: hidden;
  -webkit-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;
}

#back-top.active {
  opacity: 1;
  visibility: visible;
}

/*------------------------------------------------------------------
    47. loader-holder / .loader-holder
    -------------------------------------------------------------------*/
.loader-holder {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9);
}

.loader-holder .block {
  top: 50%;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/*------------------------------------------------------------------
    1. Logo / .logo
    -------------------------------------------------------------------*/
.logo {
  max-width: 120px;
}

@media (min-width: 576px) {
  .logo {
    max-width: 150px;
  }
}
@media (min-width: 768px) {
  .logo {
    max-width: 171px;
  }
}
/*------------------------------------------------------------------
    2. PageHeader / .pageHeader
    -------------------------------------------------------------------*/
.pageHeader {
  padding-top: 20px;
  padding-bottom: 16px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;
  -webkit-transition: padding-top 0.35s ease;
  -o-transition: padding-top 0.35s ease;
  transition: padding-top 0.35s ease;
}

@media (min-width: 576px) {
  .pageHeader {
    padding-top: 30px;
    padding-bottom: 20px;
  }
}
@media (min-width: 768px) {
  .pageHeader {
    padding-top: 40px;
  }
}
@media (min-width: 992px) {
  .pageHeader {
    padding-top: 50px;
  }
}
/*------------------------------------------------------------------
    3. HeaderFixed / .headerFixed
    -------------------------------------------------------------------*/
.headerFixed {
  margin-top: -69px;
}

@media (min-width: 576px) {
  .headerFixed {
    margin-top: -96px;
  }
}
@media (min-width: 768px) {
  .headerFixed {
    margin-top: -106px;
  }
}
@media (min-width: 992px) {
  .headerFixed {
    margin-top: -123px;
  }
}
@media (min-width: 1230px) {
  .headerFixed {
    margin-top: -125px;
  }
}
.headerFixed .pageHeader {
  padding-top: 16px;
  background-color: #fff;
  -webkit-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
}

@media (min-width: 576px) {
  .headerFixed .pageHeader {
    padding-top: 20px;
  }
}
/*------------------------------------------------------------------
    4. MainNavHolder / .mainNavHolder
    -------------------------------------------------------------------*/
.mainNavHolder {
  font-size: 16px;
  line-height: 18px;
}

@media (max-width: 575.98px) {
  .mainNavHolder {
    font-size: 14px;
    line-height: 16px;
  }
}
.mainNavHolder .nav-item {
  margin-left: 36px;
}

.mainNavHolder .nav-item .active {
  color: #4a83e5;
}

.mainNavHolder .nav-item .nav-link {
  padding: 0;
  -webkit-transition: color 0.35s ease;
  -o-transition: color 0.35s ease;
  transition: color 0.35s ease;
}

@media (min-width: 992px) {
  .mainNavHolder .nav-item .nav-link {
    color: #222;
  }
}
@media (max-width: 991.98px) {
  .mainNavHolder .nav-item .nav-link {
    background-color: #fff;
  }
}
.mainNavHolder .nav-item .nav-link:hover,
.mainNavHolder .nav-item .nav-link:active {
  color: #4a83e5;
}

.mainNavHolder .btn {
  max-width: 98px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 9px;
}

@media (min-width: 576px) {
  .mainNavHolder .btn {
    font-size: 12px;
    min-width: 138px;
    padding-top: 13px;
    padding-bottom: 13px;
    font-size: 12px;
  }
}
@media (min-width: 992px) {
  .mainNavHolder .btn {
    min-width: 175px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 14px;
  }
}
@media (min-width: 1230px) {
  .mainNavHolder .btn {
    min-width: 192px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
.mainNavHolder .btn:active,
.mainNavHolder .btn:focus,
.mainNavHolder .btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media (max-width: 991.98px) {
  .mainNavHolder .mainNavCollapse {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    margin-top: 16px;
  }
}
@media (max-width: 991.98px) and (min-width: 576px) {
  .mainNavHolder .mainNavCollapse {
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 991.98px) {
  .mainNavHolder .mainNavCollapse .navbar-nav {
    -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  }

  .mainNavHolder .mainNavCollapse .nav-item {
    margin-left: 0;
  }

  .mainNavHolder .mainNavCollapse .nav-item .active {
    color: #4a83e5;
    background-color: #fff;
  }

  .mainNavHolder .mainNavCollapse .nav-link {
    padding: 9px 15px;
    background-color: #4a83e5;
    color: #fff;
    -webkit-transition: color 0.35s ease, background-color 0.35s ease;
    -o-transition: color 0.35s ease, background-color 0.35s ease;
    transition: color 0.35s ease, background-color 0.35s ease;
  }

  .mainNavHolder .mainNavCollapse .nav-link:hover {
    color: #4a83e5;
    background-color: #fff;
  }
}
/*------------------------------------------------------------------
    5. NavToggler / .NavToggler
    -------------------------------------------------------------------*/
@media (min-width: 576px) {
  .NavToggler {
    font-size: 20px;
    padding: 5px 8px;
  }
}
/*------------------------------------------------------------------
    48. pageFooter / .pageFooter
    -------------------------------------------------------------------*/
.pageFooter {
  z-index: 1;
  min-height: 440px;
  background-color: #f3f6fc;
}

@media (min-width: 768px) {
  .pageFooter {
    min-height: 500px;
  }
}
@media (min-width: 992px) {
  .pageFooter {
    min-height: 550px;
  }
}
@media (min-width: 1230px) {
  .pageFooter {
    min-height: 627px;
  }
}
.pageFooter a {
  color: #999;
  -webkit-transition: color 0.35s ease;
  -o-transition: color 0.35s ease;
  transition: color 0.35s ease;
}

.pageFooter a:hover {
  color: #4a83e5;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .pageFooter .txtWrap {
    line-height: 22px;
  }
}
/*------------------------------------------------------------------
    49. circleGradient / .circleGradient
    -------------------------------------------------------------------*/
.circleGradient {
  width: 250px;
  height: 250px;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #4a83e5), color-stop(55%, #4a83e5));
  background: -webkit-linear-gradient(top, #4a83e5 10%, rgba(255, 255, 255, 0) 55%);
  background: -o-linear-gradient(top, #4a83e5 10%, rgba(255, 255, 255, 0) 55%);
  background: linear-gradient(to bottom, #4a83e5 10%, rgba(255, 255, 255, 0) 55%);
  z-index: -1;
}

@media (min-width: 992px) {
  .circleGradient {
    width: 320px;
    height: 320px;
  }
}
@media (min-width: 1230px) {
  .circleGradient {
    width: 403px;
    height: 403px;
  }
}
.circleGradient[data-circle=circleI] {
  -webkit-transform: rotate(28deg);
  -ms-transform: rotate(28deg);
  transform: rotate(28deg);
  top: 484px;
  right: -90px;
  background: -webkit-gradient(linear, left top, left bottom, from(#cee0ff), color-stop(70%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(top, #cee0ff 0%, rgba(255, 255, 255, 0) 70%);
  background: -o-linear-gradient(top, #cee0ff 0%, rgba(255, 255, 255, 0) 70%);
  background: linear-gradient(to bottom, #cee0ff 0%, rgba(255, 255, 255, 0) 70%);
}

@media (min-width: 576px) {
  .circleGradient[data-circle=circleI] {
    -webkit-transform: rotate(21deg);
    -ms-transform: rotate(21deg);
    transform: rotate(21deg);
    top: 175px;
    right: -100px;
  }
}
@media (min-width: 768px) {
  .circleGradient[data-circle=circleI] {
    top: 135px;
  }
}
@media (min-width: 1230px) {
  .circleGradient[data-circle=circleI] {
    right: -41px;
  }
}
.circleGradient[data-circle=circleII] {
  background: -webkit-gradient(linear, left top, left bottom, from(#adc6f1), color-stop(70%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(top, #b5cdf7 0%, rgba(255, 255, 255, 0) 70%);
  background: -o-linear-gradient(top, #b5cdf7 0%, rgba(255, 255, 255, 0) 70%);
  background: linear-gradient(to bottom, #b5cdf7 0%, rgba(255, 255, 255, 0) 70%);
  top: 300px;
  right: -100px;
  -webkit-transform: rotate(-40deg);
  -ms-transform: rotate(-40deg);
  transform: rotate(-40deg);
}

@media (min-width: 576px) {
  .circleGradient[data-circle=circleII] {
    top: 90px;
    right: 20px;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
  }
}
@media (min-width: 768px) {
  .circleGradient[data-circle=circleII] {
    top: 100px;
  }
}
@media (min-width: 992px) {
  .circleGradient[data-circle=circleII] {
    right: 80px;
  }
}
@media (min-width: 1230px) {
  .circleGradient[data-circle=circleII] {
    top: 70px;
    right: 140px;
  }
}
/*------------------------------------------------------------------
    50. ovelGradient / .ovelGradient
    -------------------------------------------------------------------*/
.ovelGradient {
  top: 0;
  left: 0;
  z-index: -1;
  width: 165px;
  height: 309px;
}

@media (min-width: 992px) {
  .ovelGradient {
    width: 215px;
    height: 403px;
  }
}
@media (min-width: 1230px) {
  .ovelGradient {
    width: 255px;
    height: 478px;
  }
}
/*------------------------------------------------------------------
    51. fLogo / .fLogo
    -------------------------------------------------------------------*/
.fLogo {
  max-width: 51px;
}

/*------------------------------------------------------------------
    52. fLink / .fLink
    -------------------------------------------------------------------*/
.fLink {
  line-height: 18px;
}

.fLink li {
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .fLink li {
    margin-bottom: 18px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .fLink {
    font-size: 14px;
  }
}
/*------------------------------------------------------------------
    53. socialNetworks / .socialNetworks
    -------------------------------------------------------------------*/
.socialNetworks {
  font-size: 14px;
  line-height: 16px;
  margin-left: -12px;
  margin-right: -12px;
}

@media (min-width: 992px) {
  .socialNetworks {
    margin-left: -16px;
    margin-right: -16px;
  }
}
.socialNetworks li {
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .socialNetworks li {
    padding-left: 16px;
    padding-right: 16px;
  }
}
/*------------------------------------------------------------------
    54. footerArea / .footerArea
    -------------------------------------------------------------------*/
.footerArea a {
  color: #cee0ff;
  -webkit-transition: color 0.35s ease;
  -o-transition: color 0.35s ease;
  transition: color 0.35s ease;
}

.footerArea a:hover {
  color: #222;
}

.footerArea p {
  margin-bottom: 0;
}

/* example lightbox styles */
.popup-holder {
  position: relative;
  overflow: hidden;
  height: 0;
}

.lightbox-demo {
  padding: 5px;
  width: 300px;
}

.lightbox-demo h2 {
  margin: 0 0 10px;
}

.lightbox-demo p {
  margin: 0;
}

/* Flaticon icon font: Flaticon */
@font-face {
  font-family: "Flaticon";
  src: url("assets/fonts/Flaticon.eot");
  src: url("assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"), url("assets/fonts/Flaticon.woff") format("woff"), url("assets/fonts/Flaticon.ttf") format("truetype"), url("assets/fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./assets/fonts/Flaticon.svg#Flaticon") format("svg");
  }
}
[class^=flaticon-]:before,
[class*=" flaticon-"]:before,
[class^=flaticon-]:after,
[class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-size: 20px;
  font-style: normal;
  margin-left: 20px;
}

.flaticon-add:before {
  content: "";
}

.flaticon-add-1:before {
  content: "";
}

.flaticon-add-2:before {
  content: "";
}

.flaticon-add-3:before {
  content: "";
}

.flaticon-agenda:before {
  content: "";
}

.flaticon-alarm:before {
  content: "";
}

.flaticon-alarm-1:before {
  content: "";
}

.flaticon-alarm-clock:before {
  content: "";
}

.flaticon-alarm-clock-1:before {
  content: "";
}

.flaticon-albums:before {
  content: "";
}

.flaticon-app:before {
  content: "";
}

.flaticon-archive:before {
  content: "";
}

.flaticon-archive-1:before {
  content: "";
}

.flaticon-archive-2:before {
  content: "";
}

.flaticon-archive-3:before {
  content: "";
}

.flaticon-attachment:before {
  content: "";
}

.flaticon-back:before {
  content: "";
}

.flaticon-battery:before {
  content: "";
}

.flaticon-battery-1:before {
  content: "";
}

.flaticon-battery-2:before {
  content: "";
}

.flaticon-battery-3:before {
  content: "";
}

.flaticon-battery-4:before {
  content: "";
}

.flaticon-battery-5:before {
  content: "";
}

.flaticon-battery-6:before {
  content: "";
}

.flaticon-battery-7:before {
  content: "";
}

.flaticon-battery-8:before {
  content: "";
}

.flaticon-battery-9:before {
  content: "";
}

.flaticon-binoculars:before {
  content: "";
}

.flaticon-blueprint:before {
  content: "";
}

.flaticon-bluetooth:before {
  content: "";
}

.flaticon-bluetooth-1:before {
  content: "";
}

.flaticon-bookmark:before {
  content: "";
}

.flaticon-bookmark-1:before {
  content: "";
}

.flaticon-briefcase:before {
  content: "";
}

.flaticon-broken-link:before {
  content: "";
}

.flaticon-calculator:before {
  content: "";
}

.flaticon-calculator-1:before {
  content: "";
}

.flaticon-calendar:before {
  content: "";
}

.flaticon-calendar-1:before {
  content: "";
}

.flaticon-calendar-2:before {
  content: "";
}

.flaticon-calendar-3:before {
  content: "";
}

.flaticon-calendar-4:before {
  content: "";
}

.flaticon-calendar-5:before {
  content: "";
}

.flaticon-calendar-6:before {
  content: "";
}

.flaticon-calendar-7:before {
  content: "";
}

.flaticon-checked:before {
  content: "";
}

.flaticon-checked-1:before {
  content: "";
}

.flaticon-clock:before {
  content: "";
}

.flaticon-clock-1:before {
  content: "";
}

.flaticon-close:before {
  content: "";
}

.flaticon-cloud:before {
  content: "";
}

.flaticon-cloud-computing:before {
  content: "";
}

.flaticon-cloud-computing-1:before {
  content: "";
}

.flaticon-cloud-computing-2:before {
  content: "";
}

.flaticon-cloud-computing-3:before {
  content: "";
}

.flaticon-cloud-computing-4:before {
  content: "";
}

.flaticon-cloud-computing-5:before {
  content: "";
}

.flaticon-command:before {
  content: "";
}

.flaticon-compact-disc:before {
  content: "";
}

.flaticon-compact-disc-1:before {
  content: "";
}

.flaticon-compact-disc-2:before {
  content: "";
}

.flaticon-compass:before {
  content: "";
}

.flaticon-compose:before {
  content: "";
}

.flaticon-controls:before {
  content: "";
}

.flaticon-controls-1:before {
  content: "";
}

.flaticon-controls-2:before {
  content: "";
}

.flaticon-controls-3:before {
  content: "";
}

.flaticon-controls-4:before {
  content: "";
}

.flaticon-controls-5:before {
  content: "";
}

.flaticon-controls-6:before {
  content: "";
}

.flaticon-controls-7:before {
  content: "";
}

.flaticon-controls-8:before {
  content: "";
}

.flaticon-controls-9:before {
  content: "";
}

.flaticon-database:before {
  content: "";
}

.flaticon-database-1:before {
  content: "";
}

.flaticon-database-2:before {
  content: "";
}

.flaticon-database-3:before {
  content: "";
}

.flaticon-diamond:before {
  content: "";
}

.flaticon-diploma:before {
  content: "";
}

.flaticon-dislike:before {
  content: "";
}

.flaticon-dislike-1:before {
  content: "";
}

.flaticon-divide:before {
  content: "";
}

.flaticon-divide-1:before {
  content: "";
}

.flaticon-division:before {
  content: "";
}

.flaticon-document:before {
  content: "";
}

.flaticon-download:before {
  content: "";
}

.flaticon-edit:before {
  content: "";
}

.flaticon-edit-1:before {
  content: "";
}

.flaticon-eject:before {
  content: "";
}

.flaticon-eject-1:before {
  content: "";
}

.flaticon-equal:before {
  content: "";
}

.flaticon-equal-1:before {
  content: "";
}

.flaticon-equal-2:before {
  content: "";
}

.flaticon-error:before {
  content: "";
}

.flaticon-exit:before {
  content: "";
}

.flaticon-exit-1:before {
  content: "";
}

.flaticon-exit-2:before {
  content: "";
}

.flaticon-eyeglasses:before {
  content: "";
}

.flaticon-fast-forward:before {
  content: "";
}

.flaticon-fast-forward-1:before {
  content: "";
}

.flaticon-fax:before {
  content: "";
}

.flaticon-file:before {
  content: "";
}

.flaticon-file-1:before {
  content: "";
}

.flaticon-file-2:before {
  content: "";
}

.flaticon-film:before {
  content: "";
}

.flaticon-fingerprint:before {
  content: "";
}

.flaticon-flag:before {
  content: "";
}

.flaticon-flag-1:before {
  content: "";
}

.flaticon-flag-2:before {
  content: "";
}

.flaticon-flag-3:before {
  content: "";
}

.flaticon-flag-4:before {
  content: "";
}

.flaticon-focus:before {
  content: "";
}

.flaticon-folder:before {
  content: "";
}

.flaticon-folder-1:before {
  content: "";
}

.flaticon-folder-10:before {
  content: "";
}

.flaticon-folder-11:before {
  content: "";
}

.flaticon-folder-12:before {
  content: "";
}

.flaticon-folder-13:before {
  content: "";
}

.flaticon-folder-14:before {
  content: "";
}

.flaticon-folder-15:before {
  content: "";
}

.flaticon-folder-16:before {
  content: "";
}

.flaticon-folder-17:before {
  content: "";
}

.flaticon-folder-18:before {
  content: "";
}

.flaticon-folder-19:before {
  content: "";
}

.flaticon-folder-2:before {
  content: "";
}

.flaticon-folder-3:before {
  content: "";
}

.flaticon-folder-4:before {
  content: "";
}

.flaticon-folder-5:before {
  content: "";
}

.flaticon-folder-6:before {
  content: "";
}

.flaticon-folder-7:before {
  content: "";
}

.flaticon-folder-8:before {
  content: "";
}

.flaticon-folder-9:before {
  content: "";
}

.flaticon-forbidden:before {
  content: "";
}

.flaticon-funnel:before {
  content: "";
}

.flaticon-garbage:before {
  content: "";
}

.flaticon-garbage-1:before {
  content: "";
}

.flaticon-garbage-2:before {
  content: "";
}

.flaticon-gift:before {
  content: "";
}

.flaticon-help:before {
  content: "";
}

.flaticon-hide:before {
  content: "";
}

.flaticon-hold:before {
  content: "";
}

.flaticon-home:before {
  content: "";
}

.flaticon-home-1:before {
  content: "";
}

.flaticon-home-2:before {
  content: "";
}

.flaticon-hourglass:before {
  content: "";
}

.flaticon-hourglass-1:before {
  content: "";
}

.flaticon-hourglass-2:before {
  content: "";
}

.flaticon-hourglass-3:before {
  content: "";
}

.flaticon-house:before {
  content: "";
}

.flaticon-id-card:before {
  content: "";
}

.flaticon-id-card-1:before {
  content: "";
}

.flaticon-id-card-2:before {
  content: "";
}

.flaticon-id-card-3:before {
  content: "";
}

.flaticon-id-card-4:before {
  content: "";
}

.flaticon-id-card-5:before {
  content: "";
}

.flaticon-idea:before {
  content: "";
}

.flaticon-incoming:before {
  content: "";
}

.flaticon-infinity:before {
  content: "";
}

.flaticon-info:before {
  content: "";
}

.flaticon-internet:before {
  content: "";
}

.flaticon-key:before {
  content: "";
}

.flaticon-lamp:before {
  content: "";
}

.flaticon-layers:before {
  content: "";
}

.flaticon-layers-1:before {
  content: "";
}

.flaticon-like:before {
  content: "";
}

.flaticon-like-1:before {
  content: "";
}

.flaticon-like-2:before {
  content: "";
}

.flaticon-link:before {
  content: "";
}

.flaticon-list:before {
  content: "";
}

.flaticon-list-1:before {
  content: "";
}

.flaticon-lock:before {
  content: "";
}

.flaticon-lock-1:before {
  content: "";
}

.flaticon-locked:before {
  content: "";
}

.flaticon-locked-1:before {
  content: "";
}

.flaticon-locked-2:before {
  content: "";
}

.flaticon-locked-3:before {
  content: "";
}

.flaticon-locked-4:before {
  content: "";
}

.flaticon-locked-5:before {
  content: "";
}

.flaticon-locked-6:before {
  content: "";
}

.flaticon-login:before {
  content: "";
}

.flaticon-magic-wand:before {
  content: "";
}

.flaticon-magnet:before {
  content: "";
}

.flaticon-magnet-1:before {
  content: "";
}

.flaticon-magnet-2:before {
  content: "";
}

.flaticon-map:before {
  content: "";
}

.flaticon-map-1:before {
  content: "";
}

.flaticon-map-2:before {
  content: "";
}

.flaticon-map-location:before {
  content: "";
}

.flaticon-megaphone:before {
  content: "";
}

.flaticon-megaphone-1:before {
  content: "";
}

.flaticon-menu:before {
  content: "";
}

.flaticon-menu-1:before {
  content: "";
}

.flaticon-menu-2:before {
  content: "";
}

.flaticon-menu-3:before {
  content: "";
}

.flaticon-menu-4:before {
  content: "";
}

.flaticon-microphone:before {
  content: "";
}

.flaticon-microphone-1:before {
  content: "";
}

.flaticon-minus:before {
  content: "";
}

.flaticon-minus-1:before {
  content: "";
}

.flaticon-more:before {
  content: "";
}

.flaticon-more-1:before {
  content: "";
}

.flaticon-more-2:before {
  content: "";
}

.flaticon-multiply:before {
  content: "";
}

.flaticon-multiply-1:before {
  content: "";
}

.flaticon-music-player:before {
  content: "";
}

.flaticon-music-player-1:before {
  content: "";
}

.flaticon-music-player-2:before {
  content: "";
}

.flaticon-music-player-3:before {
  content: "";
}

.flaticon-mute:before {
  content: "";
}

.flaticon-muted:before {
  content: "";
}

.flaticon-navigation:before {
  content: "";
}

.flaticon-navigation-1:before {
  content: "";
}

.flaticon-network:before {
  content: "";
}

.flaticon-newspaper:before {
  content: "";
}

.flaticon-next:before {
  content: "";
}

.flaticon-note:before {
  content: "";
}

.flaticon-notebook:before {
  content: "";
}

.flaticon-notebook-1:before {
  content: "";
}

.flaticon-notebook-2:before {
  content: "";
}

.flaticon-notebook-3:before {
  content: "";
}

.flaticon-notebook-4:before {
  content: "";
}

.flaticon-notebook-5:before {
  content: "";
}

.flaticon-notepad:before {
  content: "";
}

.flaticon-notepad-1:before {
  content: "";
}

.flaticon-notepad-2:before {
  content: "";
}

.flaticon-notification:before {
  content: "";
}

.flaticon-paper-plane:before {
  content: "";
}

.flaticon-paper-plane-1:before {
  content: "";
}

.flaticon-pause:before {
  content: "";
}

.flaticon-pause-1:before {
  content: "";
}

.flaticon-percent:before {
  content: "";
}

.flaticon-percent-1:before {
  content: "";
}

.flaticon-perspective:before {
  content: "";
}

.flaticon-photo-camera:before {
  content: "";
}

.flaticon-photo-camera-1:before {
  content: "";
}

.flaticon-photos:before {
  content: "";
}

.flaticon-picture:before {
  content: "";
}

.flaticon-picture-1:before {
  content: "";
}

.flaticon-picture-2:before {
  content: "";
}

.flaticon-pin:before {
  content: "";
}

.flaticon-placeholder:before {
  content: "";
}

.flaticon-placeholder-1:before {
  content: "";
}

.flaticon-placeholder-2:before {
  content: "";
}

.flaticon-placeholder-3:before {
  content: "";
}

.flaticon-placeholders:before {
  content: "";
}

.flaticon-play-button:before {
  content: "";
}

.flaticon-play-button-1:before {
  content: "";
}

.flaticon-plus:before {
  content: "";
}

.flaticon-power:before {
  content: "";
}

.flaticon-previous:before {
  content: "";
}

.flaticon-price-tag:before {
  content: "";
}

.flaticon-print:before {
  content: "";
}

.flaticon-push-pin:before {
  content: "";
}

.flaticon-radar:before {
  content: "";
}

.flaticon-reading:before {
  content: "";
}

.flaticon-record:before {
  content: "";
}

.flaticon-repeat:before {
  content: "";
}

.flaticon-repeat-1:before {
  content: "";
}

.flaticon-restart:before {
  content: "";
}

.flaticon-resume:before {
  content: "";
}

.flaticon-rewind:before {
  content: "";
}

.flaticon-rewind-1:before {
  content: "";
}

.flaticon-route:before {
  content: "";
}

.flaticon-save:before {
  content: "";
}

.flaticon-search:before {
  content: "";
}

.flaticon-search-1:before {
  content: "";
}

.flaticon-send:before {
  content: "";
}

.flaticon-server:before {
  content: "";
}

.flaticon-server-1:before {
  content: "";
}

.flaticon-server-2:before {
  content: "";
}

.flaticon-server-3:before {
  content: "";
}

.flaticon-settings:before {
  content: "";
}

.flaticon-settings-1:before {
  content: "";
}

.flaticon-settings-2:before {
  content: "";
}

.flaticon-settings-3:before {
  content: "";
}

.flaticon-settings-4:before {
  content: "";
}

.flaticon-settings-5:before {
  content: "";
}

.flaticon-settings-6:before {
  content: "";
}

.flaticon-settings-7:before {
  content: "";
}

.flaticon-settings-8:before {
  content: "";
}

.flaticon-settings-9:before {
  content: "";
}

.flaticon-share:before {
  content: "";
}

.flaticon-share-1:before {
  content: "";
}

.flaticon-share-2:before {
  content: "";
}

.flaticon-shuffle:before {
  content: "";
}

.flaticon-shuffle-1:before {
  content: "";
}

.flaticon-shutdown:before {
  content: "";
}

.flaticon-sign:before {
  content: "";
}

.flaticon-sign-1:before {
  content: "";
}

.flaticon-skip:before {
  content: "";
}

.flaticon-smartphone:before {
  content: "";
}

.flaticon-smartphone-1:before {
  content: "";
}

.flaticon-smartphone-10:before {
  content: "";
}

.flaticon-smartphone-11:before {
  content: "";
}

.flaticon-smartphone-2:before {
  content: "";
}

.flaticon-smartphone-3:before {
  content: "";
}

.flaticon-smartphone-4:before {
  content: "";
}

.flaticon-smartphone-5:before {
  content: "";
}

.flaticon-smartphone-6:before {
  content: "";
}

.flaticon-smartphone-7:before {
  content: "";
}

.flaticon-smartphone-8:before {
  content: "";
}

.flaticon-smartphone-9:before {
  content: "";
}

.flaticon-speaker:before {
  content: "";
}

.flaticon-speaker-1:before {
  content: "";
}

.flaticon-speaker-2:before {
  content: "";
}

.flaticon-speaker-3:before {
  content: "";
}

.flaticon-speaker-4:before {
  content: "";
}

.flaticon-speaker-5:before {
  content: "";
}

.flaticon-speaker-6:before {
  content: "";
}

.flaticon-speaker-7:before {
  content: "";
}

.flaticon-speaker-8:before {
  content: "";
}

.flaticon-spotlight:before {
  content: "";
}

.flaticon-star:before {
  content: "";
}

.flaticon-star-1:before {
  content: "";
}

.flaticon-stop:before {
  content: "";
}

.flaticon-stop-1:before {
  content: "";
}

.flaticon-stopwatch:before {
  content: "";
}

.flaticon-stopwatch-1:before {
  content: "";
}

.flaticon-stopwatch-2:before {
  content: "";
}

.flaticon-stopwatch-3:before {
  content: "";
}

.flaticon-stopwatch-4:before {
  content: "";
}

.flaticon-street:before {
  content: "";
}

.flaticon-street-1:before {
  content: "";
}

.flaticon-substract:before {
  content: "";
}

.flaticon-substract-1:before {
  content: "";
}

.flaticon-success:before {
  content: "";
}

.flaticon-switch:before {
  content: "";
}

.flaticon-switch-1:before {
  content: "";
}

.flaticon-switch-2:before {
  content: "";
}

.flaticon-switch-3:before {
  content: "";
}

.flaticon-switch-4:before {
  content: "";
}

.flaticon-switch-5:before {
  content: "";
}

.flaticon-switch-6:before {
  content: "";
}

.flaticon-switch-7:before {
  content: "";
}

.flaticon-tabs:before {
  content: "";
}

.flaticon-tabs-1:before {
  content: "";
}

.flaticon-target:before {
  content: "";
}

.flaticon-television:before {
  content: "";
}

.flaticon-television-1:before {
  content: "";
}

.flaticon-time:before {
  content: "";
}

.flaticon-trash:before {
  content: "";
}

.flaticon-umbrella:before {
  content: "";
}

.flaticon-unlink:before {
  content: "";
}

.flaticon-unlocked:before {
  content: "";
}

.flaticon-unlocked-1:before {
  content: "";
}

.flaticon-unlocked-2:before {
  content: "";
}

.flaticon-upload:before {
  content: "";
}

.flaticon-user:before {
  content: "";
}

.flaticon-user-1:before {
  content: "";
}

.flaticon-user-2:before {
  content: "";
}

.flaticon-user-3:before {
  content: "";
}

.flaticon-user-4:before {
  content: "";
}

.flaticon-user-5:before {
  content: "";
}

.flaticon-user-6:before {
  content: "";
}

.flaticon-user-7:before {
  content: "";
}

.flaticon-users:before {
  content: "";
}

.flaticon-users-1:before {
  content: "";
}

.flaticon-video-camera:before {
  content: "";
}

.flaticon-video-camera-1:before {
  content: "";
}

.flaticon-video-player:before {
  content: "";
}

.flaticon-video-player-1:before {
  content: "";
}

.flaticon-video-player-2:before {
  content: "";
}

.flaticon-view:before {
  content: "";
}

.flaticon-view-1:before {
  content: "";
}

.flaticon-view-2:before {
  content: "";
}

.flaticon-volume-control:before {
  content: "";
}

.flaticon-volume-control-1:before {
  content: "";
}

.flaticon-warning:before {
  content: "";
}

.flaticon-wifi:before {
  content: "";
}

.flaticon-wifi-1:before {
  content: "";
}

.flaticon-windows:before {
  content: "";
}

.flaticon-windows-1:before {
  content: "";
}

.flaticon-windows-2:before {
  content: "";
}

.flaticon-windows-3:before {
  content: "";
}

.flaticon-windows-4:before {
  content: "";
}

.flaticon-wireless-internet:before {
  content: "";
}

.flaticon-worldwide:before {
  content: "";
}

.flaticon-worldwide-1:before {
  content: "";
}

.flaticon-zoom-in:before {
  content: "";
}

.flaticon-zoom-out:before {
  content: "";
}

/* WHY US SECTION */
.frosted-glass {
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.35);
  background-color: #ecf2fd38;
  background-color: #ecf2fd60;
  backdrop-filter: blur(5px);
}